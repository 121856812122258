import React, { FC } from 'react'
import isEmpty from 'lodash/isEmpty'
import InputField from 'components/Common/InputField'
import { getErrorMessage } from 'utils/helper'

const InputFieldComponent: FC<any> = ({
  mask,
  label,
  isSubmitted,
  isCompletedAssessment,
  selectedOption,
  handleChange,
  infoText,
}) => {
  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault()
    }
  }

  return (
    <div className={`${infoText ? '' : 'mt-6'} sm:max-w-[510px]`}>
      <InputField
        mask={mask}
        placeholder={mask}
        label={label}
        type="text"
        name="question"
        disabled={isCompletedAssessment}
        className="text-left"
        onKeyPress={handleKeyPress}
        onChange={(e) => handleChange(e.target.value)}
        value={selectedOption ? selectedOption : ''}
        errors={isSubmitted && isEmpty(selectedOption) ? { message: getErrorMessage(label) } : ''}
        infoText={infoText}
      />
    </div>
  )
}

export default InputFieldComponent
