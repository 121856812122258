import React, { useEffect } from 'react'

import { isEmpty } from 'lodash'
import { Controller } from 'react-hook-form'

import InputField from 'components/Common/InputField'

import { scrollToTop } from 'utils/helper'

const EditUserProfile = ({
  error,
  errors,
  control,
  register,
  resetErrorAction,
  mobileContactError,
}) => {
  useEffect(() => {
    scrollToTop()
  }, [error, mobileContactError])

  useEffect(() => {
    return () => resetErrorAction()
    // eslint-disable-next-line
  }, [errors])

  return (
    <>
      <div className="xs:col-span-12 sm:col-span-6">
        <InputField
          errors={errors.firstName}
          type={'text'}
          label="First Name"
          placeholder="Enter First Name"
          {...register('firstName')}
        />
      </div>
      <div className="xs:col-span-12 sm:col-span-6">
        <InputField
          errors={errors.lastName}
          type={'text'}
          label="Last Name"
          placeholder="Enter Last Name"
          {...register('lastName')}
        />
      </div>
      <div className="xs:col-span-12 sm:col-span-6 mt-7">
        <InputField
          errors={errors.email}
          type={'email'}
          label="Email Address (used for login)"
          placeholder="name@email.com"
          {...register('email')}
          disabled={true}
        />
      </div>
      <div className="col-span-12 sm:col-span-6 mt-7">
        <Controller
          name={'mobileContactNumber'}
          control={control}
          render={({ field }) => (
            <InputField
              type="text"
              placeholder="Enter Phone Number"
              mask="(999) 999-9999"
              label="Phone Number"
              value={field.value}
              errors={errors.mobileContactNumber || mobileContactError}
              onChange={(e) => {
                field.onChange(e.target.value)
                if (!isEmpty(mobileContactError)) {
                  resetErrorAction()
                }
              }}
            />
          )}
        />
      </div>
    </>
  )
}
export default EditUserProfile
