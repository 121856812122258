import React, { FC, useEffect } from 'react'
import Button from 'components/Common/Button'

import { useForm, Controller } from 'react-hook-form'
import SelectField from 'components/Common/SelectField'
import { FORM, ROLES } from 'config'
import useFormContainer from 'hooks/useFormContainer'
import { IOption } from 'types'
import { UserProfile } from '__generated__/api-types-and-hooks'
import { getRoleFromPath } from 'utils/helper'

interface IAssignAdvisorOrClientForm {
  handleOnSubmits: (data: { addUser?: IOption[] }) => void
  getAdvisorsAction: (data: { isPaginated: boolean }) => void
  advisors: IOption[]
  user: UserProfile
}
const AssignAdvisorOrClientForm: FC<IAssignAdvisorOrClientForm> = ({
  handleOnSubmits,
  getAdvisorsAction,
  advisors,
  user,
}) => {
  const methods = useForm({
    mode: 'onBlur',
  })
  const { control, getValues } = methods

  useEffect(() => {
    getAdvisorsAction({ isPaginated: false })
  }, [getAdvisorsAction])
  const onSubmit = () => {
    handleOnSubmits({ addUser: getValues().advisors })
  }

  useFormContainer(FORM.ADVISOR_FORM, methods)
  const isAdmin = getRoleFromPath() === ROLES.BSO_ADMIN
  return (
    <>
      <h2 className="text-4xl text-center font-regular text-primary-text font-primary leading-10 font-body pb-8">
        {`Assign Advisor`}
      </h2>

      <form>
        <div className="grid grid-cols-1 gap-8 mt-8">
          <Controller
            name={'advisors'}
            control={control}
            defaultValue={isAdmin ? [] : [user.id]}
            render={({ field }) => (
              <SelectField
                {...field}
                isMulti={true}
                label={`Advisor(s)`}
                options={advisors}
                onChange={(value) => {
                  field.onChange(value)
                }}
              />
            )}
          />
          <div className="">
            <Button
              type="button"
              label="CONTINUE"
              testId="submit"
              variant="primary"
              onClick={() => onSubmit()}
              className="w-full"
            />
          </div>

          <div>
            <Button
              disabled={getValues().advisors && getValues().advisors.length > 0}
              label="SKIP"
              testId="assignAdvisorSkipButton"
              variant="text"
              type={'button'}
              className="w-full"
              onClick={() => handleOnSubmits({})}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default AssignAdvisorOrClientForm
