import React, { useMemo } from 'react'
import { compact, join } from 'lodash'
import { timeDuration, viewMeetingDetail } from 'utils/helper'
import { IUSER_NOTIFICATION_TYPES, ROLES } from 'components/NotificationPanel/types'
import Button from 'components/Common/Button'
import {
  MessageTextWrapper,
  MessageWrapper,
  NotificationTextWrapper,
} from 'components/NotificationPanel/style'

const Message = ({ notification, handleClose }) => {
  const userName = useMemo(() => {
    return join(compact([notification?.firstName, notification?.lastName]), ' ')
  }, [notification])
  return (
    <MessageWrapper>
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING && (
        <>
          <MessageTextWrapper>
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </MessageTextWrapper>
          has scheduled a meeting
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING_RESCHEDULED && (
        <>
          <MessageTextWrapper>
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </MessageTextWrapper>
          rescheduled a meeting
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING_CANCELLED && (
        <>
          <MessageTextWrapper>
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </MessageTextWrapper>
          cancelled a meeting
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.USER_DISABLED && (
        <>
          <MessageTextWrapper>
            The account for {`${userName} `}
            {notification.businessName ? `of ${notification.businessName} ` : ''}
          </MessageTextWrapper>
          has been disabled.
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CHAT && (
        <>
          <MessageTextWrapper>
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </MessageTextWrapper>
          sent a chat
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CLIENT_USER_PROFILE_UPDATE && (
        <>
          {notification?.meta?.notificationGeneratedBy === ROLES.BUSINESS_OWNER ? (
            <>
              <MessageTextWrapper>
                {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
              </MessageTextWrapper>
              has updated their user profile details
            </>
          ) : (
            <>
              Personal profile details for
              <MessageTextWrapper>
                {` ${userName}`}
                {notification.businessName ? ` of ${notification.businessName} ` : ''}
              </MessageTextWrapper>
              have been updated.
            </>
          )}
        </>
      )}
      {notification.notificationType ===
        IUSER_NOTIFICATION_TYPES.CLIENT_BUSINESS_PROFILE_UPDATE && (
        <>
          {notification?.meta?.notificationGeneratedBy === ROLES.BUSINESS_OWNER ? (
            <>
              <MessageTextWrapper>
                {userName} {notification.businessName ? ` of ${notification.businessName} ` : ''}
              </MessageTextWrapper>
              has updated their business profile details.
            </>
          ) : (
            <>
              Business profile details for
              <MessageTextWrapper>
                {` ${userName}`}
                {notification.businessName ? ` of ${notification.businessName} ` : ''}
              </MessageTextWrapper>
              have been updated.
            </>
          )}
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.NEW_CLIENT_ADDED && (
        <>
          <MessageTextWrapper> {`${userName} `} </MessageTextWrapper>
          has been assigned to you.
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CLIENT_REMOVED && (
        <>
          You have been unassigned
          <MessageTextWrapper>{` ${userName}`} </MessageTextWrapper>
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.MONTHLY_ATTESTATION_UPDATE && (
        <>
          <MessageTextWrapper> {userName} </MessageTextWrapper> {notification.meta.text}
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.ANNOUNCEMENT && (
        <>
          <MessageTextWrapper> {userName} </MessageTextWrapper>
          <span>sent an announcement: </span>
          <MessageTextWrapper>
            <NotificationTextWrapper>{notification.meta.text}</NotificationTextWrapper>
          </MessageTextWrapper>
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.MONTHLY_ATTESTATION_TIME && (
        <>
          <MessageTextWrapper>{userName} </MessageTextWrapper>
          {notification.meta.text}
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING_UPDATED && (
        <>
          <MessageTextWrapper>
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </MessageTextWrapper>
          edited a meeting
          <Button
            variant="text"
            label="View details"
            onClick={() => {
              viewMeetingDetail(notification, handleClose)
            }}
          />
        </>
      )}
      {notification.createdAt && (
        <div className="font-normal mt-1 leading-3 text-xs">
          {timeDuration(notification.createdAt)}
        </div>
      )}
    </MessageWrapper>
  )
}

export default Message
