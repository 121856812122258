import React from 'react'

import { AppFC } from 'types'
import useNavigateTo from 'hooks/useNavigateTo'
import Heading from 'components/Common/Heading'
import useDateLabeler from 'hooks/useDateLabeler'
import ActionCard from 'components/Common/ActionCard'

export interface IEventCardProps {
  date: string
  description: string
}

const EventCard: AppFC<IEventCardProps> = ({ date, description }) => {
  const navigate = useNavigateTo('calendar')
  const labeler = useDateLabeler(date)
  return (
    <ActionCard
      className="hover:shadow-lg"
      onClick={navigate}
      leftSlot={() => (
        <div className="w-[102px] min-w-[102px] flex items-center justify-center">
          <p className=" text-white leading-4 font-bold text-[14px]">{labeler.shortLabel}</p>
        </div>
      )}
    >
      <div className="flex flex-col">
        <p className="text-sm font-normal whitespace-nowrap">{labeler.longLabel}</p>
        <Heading className="!text-base !leading-6">{description}</Heading>
      </div>
    </ActionCard>
  )
}

export default EventCard
