import { SmsSubscriptionStatus } from '__generated__/api-types-and-hooks'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import history from 'utils/history'

import { FORGOT_PASSWORD, FORGOT_PASSWORD_SUBMIT, LOGIN, LOGOUT, SIGNUP } from 'store/types'
import { loginAction, forgotPasswordAction, resetPasswordAction } from 'store/actions/auth'
import { userInitialRouting } from 'utils/helper'
import { deleteSession } from 'utils/user'
import AuthApi from 'api/auth'
import UserApi from 'api/user'
import {
  getUserProfileAction,
  setTenantIdAction,
  updateUserProfileAction,
} from 'store/actions/user'
import { ampli } from 'ampli'
import redirectTo from 'utils/redirectTo'
import { LOG_ACTIVITY_ACTION } from 'config'
import uuid from 'react-uuid'
// import { resetUsetiful } from 'lib/usetiful'

function* login(action) {
  const { email, password, next } = action.payload

  try {
    const user = yield call(AuthApi.login, email, password)
    yield put(loginAction.FULLFILLED({ user, disable: false }))
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      history.push('/auth/reset-password')
    } else {
      const userProfileResponse = yield call(UserApi.getUserProfile)
      if (!userProfileResponse) {
        throw new Error('An error occurred during login. Please try again later.')
      }
      if (userProfileResponse?.getMyProfile?.tenantId.length > 1) {
        history.push('/selectId-route')
      }
      yield put(setTenantIdAction(userProfileResponse?.getMyProfile?.tenantId[0]))
      const tenantId = yield select((state) => state.user.tenantId)

      const sessionToken = uuid()
      localStorage.setItem('sessionToken', sessionToken)
      yield call(UserApi.logActivity, {
        action: LOG_ACTIVITY_ACTION.SIGN_IN,
        accessToken: sessionToken,
        tenantId,
      })
      const userProfile = userProfileResponse.getMyProfile
      ampli.identify(userProfile.id)
      ampli.loggedIn({
        id: userProfile.id,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
      })
      userInitialRouting(userProfileResponse, tenantId, next)
    }
    yield put(loginAction.FULLFILLED({ user, disable: false }))
  } catch (error) {
    yield put(loginAction.REJECTED({ error }))
    ampli.loginFailed({ email: email })
    console.log(error)
  }
}

function* signup(action) {
  const { email, password, firstName, lastName } = action.payload
  yield call(AuthApi.signup, { email, password, firstName, lastName })
  history.push('/login')
}

function* forgotPassword(action) {
  try {
    const { email } = action.payload
    yield call(AuthApi.forgotPassword, email)
    yield put(forgotPasswordAction.FULLFILLED({ disable: false }))
    ampli.passwordResetInitiated({ email: email })
  } catch (error) {
    yield put(forgotPasswordAction.REJECTED({ error }))
    console.log(error)
  }
}

function* forgotPasswordSubmit(action) {
  const user = yield select((state) => state.auth.user)

  const { email, code, password } = action.payload
  try {
    if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const response = yield call(AuthApi.changePassword, user, password)
      yield put(resetPasswordAction.FULLFILLED({ disable: false }))
      if (response) {
        const sessionToken = uuid()
        localStorage.setItem('sessionToken', sessionToken)
        const userProfileResponse = yield call(UserApi.getUserProfile)
        yield put(setTenantIdAction(userProfileResponse?.getMyProfile?.tenantId[0]))
        const tenantId = yield select((state) => state.user.tenantId)
        yield call(UserApi.logActivity, {
          action: LOG_ACTIVITY_ACTION.SIGN_IN,
          accessToken: sessionToken,
          tenantId,
          showClientActivity: false,
          logStatus: '',
        })
        const userProfile = userProfileResponse.getMyProfile
        ampli.identify(userProfile.id)
        ampli.accountActivated({ id: userProfile.id, email: userProfile.email })
        ampli.loggedIn({
          id: userProfile.id,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: userProfile.email,
        })
        userInitialRouting(userProfileResponse, tenantId)
      }
    } else {
      const response = yield call(AuthApi.forgotPasswordSubmit, email, code, password)
      yield put(resetPasswordAction.FULLFILLED({ disable: false }))
      if (response) {
        localStorage.removeItem('sessionToken')
        history.push({
          pathname: '/auth/success',
          state: { type: 'passwordChanged' },
        })
      }
      ampli.passwordResetCompleted({ email: email })
    }
  } catch (error) {
    yield put(resetPasswordAction.REJECTED({ error }))
  }
}

function* logout(action) {
  const { next } = action.payload
  const tenantId = yield select((state) => state.user.tenantId)
  const smsModalStatus = yield select((state) => state?.user?.user.smsSubscriptionStatus)
  const sessionToken = localStorage.getItem('sessionToken')

  yield call(UserApi.logActivity, {
    action: LOG_ACTIVITY_ACTION.SIGN_OUT,
    accessToken: sessionToken,
    tenantId,
  })
  if (smsModalStatus === SmsSubscriptionStatus.Closed) {
    yield put(updateUserProfileAction.STARTED({ smsSubscriptionStatus: null }))
  }
  localStorage.removeItem('sessionToken')
  yield call(AuthApi.logout)
  yield put(getUserProfileAction.FULLFILLED({ user: {}, userProfile: {} }))
  deleteSession()
  // resetUsetiful()
  ampli.loggedOut()
  redirectTo('/auth/login', next)
}
/// /////////// Watchers ///////////////////////
export function* watcherAuth() {
  yield takeLatest(LOGIN.STARTED, login)
  yield takeLatest(SIGNUP.STARTED, signup)
  yield takeLatest(FORGOT_PASSWORD.STARTED, forgotPassword)
  yield takeLatest(FORGOT_PASSWORD_SUBMIT.STARTED, forgotPasswordSubmit)
  yield takeLatest(LOGOUT, logout)
}
