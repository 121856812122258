import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FIELD_NAME, FORM, assessmentOption } from 'config'

import history from 'utils/history'
import ModalBox from 'components/Common/ModalBox'
import Button from 'components/Common/Button'
import {
  EditClientDetailsBusinessProfileContainer,
  EditClientProfileContainer,
  EditOtherDetailsContainer,
  FilesContainer,
} from 'pages/Clients/container'
import Dialog, { IDialogType } from 'components/Clients/Client/Details/EditClient/Dialog'
import { profileSchema } from 'pages/Owner/validation'
import { AppFC } from 'types'
import { useGetBusinessIndustryDetailsQuery } from '__generated__/api-types-and-hooks'
import hookForms from 'utils/hookForms'
import { isEmpty } from 'lodash'

export interface IEditClientProps {
  userId: string
  setActiveTab: (payload) => void
  setShowDetail: (payload) => void
  detailsLoading: boolean
  resetErrorAction: () => void
  isFormDataChanged: boolean
  disableButton: boolean
  setIsFormDataChanged: (payload) => void
  isUserProfileFormSet: boolean
  isBusinessProfileFormSet: boolean
  getAssessmentOptionsAction: (payload) => void
  updateClientProfileDetailAction: (payload) => void
  getClientEditProfileDetailAction: (payload) => void
}

const EditClient: AppFC<IEditClientProps> = ({
  userId,
  setActiveTab,
  setShowDetail,
  detailsLoading,
  resetErrorAction,
  isFormDataChanged,
  disableButton,
  setIsFormDataChanged,
  isUserProfileFormSet,
  getAssessmentOptionsAction,
  updateClientProfileDetailAction,
  getClientEditProfileDetailAction,
}) => {
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(profileSchema),
  })

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = methods

  let { clientId } = useParams()

  const [isIndustryChanged, setIndustryChanged] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isFormValid, setIsFormValid] = useState(true)
  const [errorsCount, setErrorsCount] = useState(Number)
  const [path, setPath] = useState('')

  const checkErrors = (errors) => {
    setErrorsCount(Object.keys(errors || {}).length)
  }
  const formValues = hookForms.getForm(FORM.CLIENT_BUSINESS_PROFILE)?.getValues()
  const { data, isLoading } = useGetBusinessIndustryDetailsQuery({
    input: { foodSubIndustryOptionIds: formValues?.foodSubIndustryOptionIds, userId },
  })
  let isFoodSubIndustryChanged = data?.getBusinessIndustryDetails?.isSubIndustryUpdated

  useEffect(() => {
    isDirty && setIsFormValid(false)
  }, [isDirty])

  useEffect(() => {
    getClientEditProfileDetailAction({
      type: 'initial',
      userId: clientId,
      setForms: [FORM.BUSINESS_OTHER_DETAILS],
    })
    return () => resetErrorAction()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    getAssessmentOptionsAction([
      ...assessmentOption.initial,
      FIELD_NAME.REGISTER_BUSINESS_STRUCTURE,
    ])
  }, [getAssessmentOptionsAction])

  useEffect(() => {
    const unblock = history.block((location, action) => {
      setPath(location?.pathname)
      if (isFormDataChanged && !showModal) {
        setShowModal(true)
        return showModal
      }
      return true
    })

    return () => {
      unblock()
    }
  }, [isFormDataChanged, showModal])

  const closeModal = () => {
    setShowModal(false)
  }
  const onSubmit = (e, forceSave = false) => {
    if ((isIndustryChanged && !forceSave) || (isFoodSubIndustryChanged && !forceSave)) {
      e.preventDefault()
      setShowModal(true)
      return
    }

    updateClientProfileDetailAction({
      userId: clientId,
      setForms: [FORM.BUSINESS_OTHER_DETAILS],
    })
    setIsFormDataChanged(false)
    setShowModal(false)
    setIndustryChanged(false)
    if (path) {
      const splitLocation = path.split('/')
      setActiveTab(splitLocation[5])
      history.push(path)
    }
    reset({}, { keepValues: true })
  }
  return (
    <div>
      <div className="grid grid-cols-12 mx-4 pt-5">
        <div className="xs:col-span-12 flex">
          {/* check-button -- clean up custom styles -- no w-full here*/}
          <Button
            label="Save Changes"
            variant="primary"
            className="mr-6"
            onClick={onSubmit}
            disabled={
              errorsCount > 0 || isFormValid || disableButton || isLoading || !isEmpty(errors)
            }
          />

          <Button
            label="Cancel"
            variant="text"
            disabled={disableButton}
            onClick={() => setShowDetail(false)}
          />
        </div>
      </div>
      <div className="grid xs:grid-cols-12 xs:gap-x-8 lg:gap-x-6 xs:gap-y-12 sm:gap-y-12 sm:pl-5 sm:pr-0">
        <EditClientProfileContainer
          checkErrors={checkErrors}
          onSubmit={onSubmit}
          isUserProfileFormSet={isUserProfileFormSet}
          checkformValueChanged={setIsFormDataChanged}
          errors={errors}
          isValid={isValid}
          isDirty={isDirty}
          control={control}
          register={register}
          methods={methods}
          handleSubmit={handleSubmit}
        />
        <EditClientDetailsBusinessProfileContainer
          onSubmit={onSubmit}
          className="col-span-12"
          checkErrors={checkErrors}
          setIsFormValid={setIsFormValid}
          setIndustryChanged={setIndustryChanged}
        />
        <EditOtherDetailsContainer
          onSubmit={onSubmit}
          detailsLoading={detailsLoading}
          setIsFormSame={setIsFormValid}
        />
        <FilesContainer userId={clientId} />
      </div>

      <div className="rounded-[10px]">
        {showModal && !isLoading && (
          <ModalBox
            onClose={closeModal}
            title={
              !isIndustryChanged && !isFoodSubIndustryChanged
                ? 'Save Changes'
                : 'Save Industry Changes'
            }
          >
            <Dialog
              path={path}
              dialogType={
                isIndustryChanged || isFoodSubIndustryChanged
                  ? IDialogType.UpdatedIndustry
                  : IDialogType.SaveChanges
              }
              onSubmit={(e) => onSubmit(e, true)}
              showModal={showModal}
              setActiveTab={setActiveTab}
              setShowModal={setShowModal}
              isIndustryChanged={isIndustryChanged}
              disableButton={disableButton || errorsCount > 0}
              isFoodSubIndustryChanged={isFoodSubIndustryChanged}
              setIsFormDataChanged={setIsFormDataChanged}
            />
          </ModalBox>
        )}
      </div>
    </div>
  )
}

export default EditClient
