export enum IUSER_NOTIFICATION_STATUS {
  READ = 'read',
  UNREAD = 'unread',
}

export enum IUSER_NOTIFICATION_TYPES {
  CALENDAR_MEETING = 'Calendar Meeting',
  CALENDAR_MEETING_RESCHEDULED = 'Calendar Meeting Rescheduled',
  CALENDAR_MEETING_CANCELLED = 'Calendar Meeting Cancelled',
  CALENDAR_MEETING_UPDATED = 'calendar.meeting.updated',
  CHAT = 'Chat',
  CLIENT_USER_PROFILE_UPDATE = 'Client User Profile Update',
  CLIENT_BUSINESS_PROFILE_UPDATE = 'Client Business Profile Update',
  NEW_CLIENT_ADDED = 'New Client Added',
  CLIENT_REMOVED = 'Client Removed',
  MONTHLY_ATTESTATION_TIME = 'Monthly Attestation Time',
  USER_DISABLED = 'User Disabled',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  MONTHLY_ATTESTATION_UPDATE = 'Monthly Attestation Update',
}
export enum ROLES {
  ADMIN = 'ADMIN',
  BSO_ADMIN = 'BSO_ADMIN',
  BSO_ADVISOR = 'BSO_ADVISOR',
  BUSINESS_OWNER = 'BUSINESS_OWNER',
}
