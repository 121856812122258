import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import '@aws-amplify/ui-react/styles.css'

import { ampli, Environment } from './ampli'
import markerSDK from '@marker.io/browser'
import { ZendeskSettings, ZendeskWidget } from './zendesk-types'
import CobrowseIO from 'cobrowse-sdk-js'

declare global {
  interface Window {
    zESettings?: ZendeskSettings
    zE?: ZendeskWidget
  }
}
if (process.env.NODE_ENV === 'production') {
  markerSDK
    .loadWidget({
      project: '6452b6a0223633bc6463e721',
      ssr: {
        renderDelay: '5000', // 0 - 15000 (ms)
      },
      silent: true,
    })
    .then((widget) => {
      if (process.env.REACT_APP_ENV !== 'prod') {
        widget.show()
      } else {
        widget.hide()
      }
      return widget
    })
}

ampli.load({
  environment: (process.env.REACT_APP_AMPLI_ENVIRONMENT || 'development') as Environment,
})

if (process.env.NODE_ENV === 'production') {
  const sentryProps: Sentry.BrowserOptions = {
    dsn: 'https://b0bc65fe14a24b829828fc08ee5a5677@o4504536900894720.ingest.sentry.io/4504578052718592',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    environment: process.env.REACT_APP_ENV || 'development',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  }
  if (process.env.REACT_APP_SENTRY_RELEASE) {
    sentryProps.release = process.env.REACT_APP_SENTRY_RELEASE
  }
  Sentry.init(sentryProps)
}

if (process.env.NODE_ENV === 'production') {
  CobrowseIO.license = 'Pqq8YE-hkWcCiw'
  CobrowseIO.start()
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
