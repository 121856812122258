import React, { useState } from 'react'
import Editor from '@draft-js-plugins/editor'
import createLinkifyPlugin from '@draft-js-plugins/linkify'
import styled from 'styled-components'
import tw from 'twin.macro'
import { EditorState, ContentBlock, convertFromHTML, ContentState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import linkifyIt from 'linkify-it'
import tlds from 'tlds'
import { replaceAll } from '../../../utils/helper'

export enum MeetingSettingsEditorStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

const MeetingSettingsEditorStyles = {
  [MeetingSettingsEditorStyle.PRIMARY]: {
    textDecoration: 'underline',
    linkColor: tw`text-black-light`,
  },
  [MeetingSettingsEditorStyle.SECONDARY]: {
    textDecoration: 'none',
    linkColor: tw`text-primary`,
  },
}

const EditorWrapper = styled.div<{ $style: MeetingSettingsEditorStyle; $disabled: boolean }>`
  ${tw`text-sm`};
  ${({ $disabled }) => ($disabled ? tw`p-0` : tw`p-3`)}
  border-radius: 0.25rem;
  border: ${({ $disabled }) => ($disabled ? 'none' : '1px solid #d4d4d8')};
  background: ${({ $disabled }) => ($disabled ? tw`bg-white` : tw`bg-secondary`)};
  flex-shrink: 0;
  margin-top: 1rem;
  min-height: ${({ $disabled }) => ($disabled ? tw`h-fit` : tw`h-36`)};
  a {
    text-decoration: ${({ $style }) =>
      MeetingSettingsEditorStyles[$style].textDecoration || 'underline'};
    color: ${({ $style }) => MeetingSettingsEditorStyles[$style].linkColor || tw`text-black-light`};
  }
  a span {
    color: ${({ $style }) => MeetingSettingsEditorStyles[$style].linkColor || tw`text-black-light`};
    &:hover {
      text-decoration: underline;
    }
  }
`

const getContentStateFromHTML = (value: string) => {
  const blocksFromHtml = convertFromHTML(value)
  const contentState = ContentState.createFromBlockArray(
    blocksFromHtml.contentBlocks,
    blocksFromHtml.entityMap
  )
  return contentState
}

const linkifyPlugin = createLinkifyPlugin({
  component(props) {
    const { href } = props
    const onLinkClick = () => {
      window.open(href, '_blank')
    }
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...props} onClick={onLinkClick} />
  },
})

const MeetingSettingsEditor = ({
  onChange,
  style = MeetingSettingsEditorStyle.PRIMARY,
  value = '',
  disabled = false,
  key = 'meeting-editor-key',
}) => {
  const contentState = getContentStateFromHTML(value)
  const content = value ? EditorState.createWithContent(contentState) : EditorState.createEmpty()
  const [editorState, setEditorState] = useState(content)

  const renderLink = (block: ContentBlock) => {
    const isLink = (text: string) => linkifyIt().tlds(tlds).set({ fuzzyEmail: false }).match(text)
    let text = block.getText()
    const matches = isLink(text)
    if (!matches) return `<p>${text}</p>`
    for (let match of matches) {
      text = text.replace(
        match.text,
        `<a href="${match.text}" target='_blank' rel='noreferrer'>${match.text}</a>`
      )
    }
    return `<p>${text}</p>`
  }

  const handleStateChange = (state: EditorState) => {
    const value = state.getCurrentContent()
    let rawHTML = stateToHTML(value, {
      blockRenderers: {
        paragraph: renderLink,
        unstyled: renderLink,
        atomic: renderLink,
      },
    })
    if (rawHTML === '<p></p>') {
      rawHTML = ''
    }
    const html = replaceAll(rawHTML, /\n/g, '')
    onChange(html)
    setEditorState(state)
  }

  return (
    <EditorWrapper data-testid="meeting-editor" $style={style} $disabled={disabled}>
      <Editor
        key={key}
        onChange={handleStateChange}
        editorState={editorState}
        plugins={[linkifyPlugin]}
        readOnly={disabled}
      />
    </EditorWrapper>
  )
}
export default MeetingSettingsEditor
